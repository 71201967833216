import '../styles/ETax.css';
import React, { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { FaPlus, FaEdit, FaTimes } from 'react-icons/fa';
import { PrepareData } from '../models/Datatable';

import CardContent from '../components/CardContent';
import Datatable from '../components/Datatable/Datatable';

// For development only
interface MockData {
    keycode: string;
    value: string;
}
// End

function ETax() {
    // Simulate data after fetched
    const memoMockData = useMemo<MockData[]>(() => [
        { keycode: 'COUNTRYCODE', value: '09' },
        { keycode: 'POSTCODE', value: '08' },
        { keycode: 'SALETAXREPORT_OBJECTIVECODE', value: 'SALETAXREPORTXREPLACEMENT' },
    ], []);

    const prepareData: PrepareData<MockData> = {
        objects: memoMockData,
        columns: [
            {
                key: 'keycode',
                header: 'KEYCODE'
            },
            {
                key: 'value',
                header: 'VALUE'
            }
        ],
        extendColumns: [
            {
                key: 'actions',
                header: '',
                cell: () => {
                    return (
                        <td className="text-end">
                            <Button
                                size="sm"
                                variant="warning"
                                className="me-2 text-white"
                            >
                                <FaEdit className="me-1" />
                                <span className="align-middle">Edit</span>
                            </Button>
                            <Button
                                size="sm"
                                variant="danger"
                            >
                                <FaTimes className="me-1" />
                                <span className="align-middle">Delete</span>
                            </Button>
                        </td>
                    )
                }
            }
        ]
    };

    return (
        <CardContent.Container>
            <CardContent.Header className="d-flex align-items-center">
                <h4 className="page-header">E-Tax</h4>
                <Button className="ms-auto">
                    <FaPlus className="me-1" />
                    <span className="align-middle">Create</span>
                </Button>
            </CardContent.Header>
            <CardContent.Body>
                <Datatable prepareData={prepareData} />
            </CardContent.Body>
        </CardContent.Container>
    );
}

export default ETax;