import React from 'react';
import { Column, ExtendColumn } from '../../models/Datatable';

interface Props<T> {
    object: T;
    columns: Column<T>[];
    extendColumns: ExtendColumn<T>[];
}

function Row<T>({ object, columns, extendColumns }: Props<T>) {
    return (
        <tr>
            {columns.map(column => (
                (column.cell === undefined)
                    ? <td key={String(column.key)}>{object[column.key]}</td>
                    : column.cell(object)
            ))}
            {extendColumns.map((extendColumn, index) => (
                <React.Fragment key={String(extendColumn.key)}>
                    {extendColumn.cell(object) || ''}
                </React.Fragment>
            ))}
        </tr>
    );
}

export default Row;