/**
 * Less "any" less bugs
 */

import './styles/App.css';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import Layout from './components/Layout';

import ETax from './pages/ETax';
import ETaxAutoConfig from './pages/ETaxAutoConfig';
import ETaxCustomerConfig from './pages/ETaxCustomerConfig';
import ETaxPrintForm from './pages/ETaxPrintForm';

function App() {
    return (
        <Layout>
            <PrivateRoute exact path='/' component={() => <Redirect to="/etax" />} />
            <PrivateRoute path='/etax' component={ETax} />
            <PrivateRoute path='/etax-auto-config' component={ETaxAutoConfig} />
            <PrivateRoute path='/etax-customer-config' component={ETaxCustomerConfig} />
            <PrivateRoute path='/etax-print-form' component={ETaxPrintForm} />
        </Layout>
    );
}

export default App;