import React from 'react';
import Table from 'react-bootstrap/Table';
import { PrepareData } from '../../models/Datatable';

import Head from './Head';
import Row from './Row';

interface DataTableProps<T> {
    prepareData: PrepareData<T>;
}

function Datatable<T>({ prepareData }: DataTableProps<T>) {
    const { objects, columns, extendColumns = [] } = prepareData;

    return (
        <Table borderless className="table">
            <thead className="table-head">
                <Head columns={columns} extendColumns={extendColumns} />
            </thead>
            <tbody className="table-body">
                {objects.map((object, index) => (
                    <Row
                        key={`table-row-${index + 1}`}
                        object={object}
                        columns={columns}
                        extendColumns={extendColumns}
                    />
                ))}
            </tbody>
        </Table>
    );
}

export default Datatable;