import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

/**
 * TODO
 * 1. Redirect App Pool
 */

interface Props extends Omit<RouteProps, 'component'> {
    component: React.ComponentType<any>;
}

function PrivateRoute({ component: Component, ...rest }: Props) {
    return (
        <Route
            {...rest}
            render={props => <Component {...props} />}
        />
    );
}

export default PrivateRoute;