import React from 'react';
import { Column, ExtendColumn } from '../../models/Datatable';

interface Props<T> {
    columns: Column<T>[];
    extendColumns: ExtendColumn<T>[];
}

function Head<T>({ columns, extendColumns }: Props<T>) {
    return (
        <tr>
            {columns.map(column => (
                <th key={String(column.key)}>{column.header}</th>
            ))}
            {extendColumns.map(extendColumn => (
                <th key={String(extendColumn.key)}>{extendColumn.header}</th>
            ))}
        </tr>
    );
}

export default Head;