import React from 'react';

interface CardContentProps {
    children: React.ReactNode;
    className?: string;
}

interface ContainerProps extends CardContentProps { }

interface HeaderProps extends Partial<CardContentProps> { }

interface CardBodyProps extends Partial<CardContentProps> { }

function Container({ children, className = '' }: ContainerProps) {
    return (
        <div className={`bg-white h-100 rounded-3 d-flex flex-column shadow-sm ${className}`}>
            {children}
        </div>
    );
}

function Header({ children, className = '' }: HeaderProps) {
    return (
        <div className={`px-3 py-2 shadow-sm ${className}`}>
            {children}
        </div>
    );
}

function Body({ children, className = '' }: CardBodyProps) {
    return (
        <div className={`p-3 flex-fill ${className}`}>
            {children}
        </div>
    );
}

const CardContent = { Container, Header, Body };
export default CardContent;