import '../styles/SideNav.css';
import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

function SideNav() {
    const { pathname } = useLocation();
    const pageLinks = useMemo(() => [
        { name: 'E-Tax', link: '/etax' },
        { name: 'E-Tax Auto Config', link: '/etax-auto-config' },
        { name: 'E-Tax Customer Config', link: '/etax-customer-config' },
        { name: 'E-Tax Print Form', link: '/etax-print-form' }
    ], []);

    return (
        <div className="sidenav">
            <ul className="list-unstyled fw-bold text-white">
                {pageLinks.map((page, index) => (
                    <Link
                        key={`sidenav-${index + 1}`}
                        className={`sidenav-item ${(pathname === page.link) ? 'active' : ''}`}
                        to={page.link}
                    >
                        {page.name}
                    </Link>
                ))}
            </ul>
        </div>
    );
}

export default SideNav;