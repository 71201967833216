import React from 'react';

import CardContent from '../components/CardContent';

function ETaxCustomerConfig() {
    return (
        <CardContent.Container>
            <CardContent.Header>
                <h4 className="page-header">E-Tax Customer Config</h4>
            </CardContent.Header>
            <CardContent.Body>
                <p>Hello World</p>
            </CardContent.Body>
        </CardContent.Container>
    );
}

export default ETaxCustomerConfig;