import '../styles/Layout.css';
import React from 'react';
import { Container } from 'react-bootstrap';

import TopNav from './TopNav';
import SideNav from './SideNav';

interface Props {
    children?: React.ReactNode;
}

function Layout(props: Props) {
    return (
        <React.Fragment>
            <TopNav />
            <div className="main-container">
                <SideNav />
                <Container fluid className="main-content">
                    {props.children}
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Layout;