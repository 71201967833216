import React, { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { FaPlus, FaEdit, FaTimes, FaCheck } from 'react-icons/fa';
import { PrepareData } from '../models/Datatable';

import CardContent from '../components/CardContent';
import Datatable from '../components/Datatable/Datatable';

interface MockData {
    documentTypeCode: string;
    etaxIpWeb: string;
    isEtax: boolean;
}

function ETaxAutoConfig() {
    const memoMockData = useMemo<MockData[]>(() => [
        { documentTypeCode: 'AA', etaxIpWeb: '', isEtax: false },
        { documentTypeCode: 'AB', etaxIpWeb: '', isEtax: false },
        { documentTypeCode: 'AC', etaxIpWeb: '', isEtax: true }
    ], []);

    const prepareData: PrepareData<MockData> = {
        objects: memoMockData,
        columns: [
            {
                key: 'documentTypeCode',
                header: 'DOCUMENT TYPE CODE'
            },
            {
                key: 'etaxIpWeb',
                header: 'E-TAX IP WEB'
            },
            {
                key: 'isEtax',
                header: 'IS E-TAX',
                cell: (objectRow) => {
                    return (
                        <td key={"555"}>
                            {
                                (objectRow.isEtax)
                                    ? <FaCheck className="text-primary" />
                                    : <FaTimes className="text-danger" />
                            }
                        </td>
                    );
                }
            }
        ],
        extendColumns: [
            {
                key: 'actions',
                header: '',
                cell: () => {
                    return (
                        <td className="text-end">
                            <Button
                                size="sm"
                                variant="warning"
                                className="me-2 text-white"
                            >
                                <FaEdit className="me-1" />
                                <span className="align-middle">Edit</span>
                            </Button>
                            <Button
                                size="sm"
                                variant="danger"
                            >
                                <FaTimes className="me-1" />
                                <span className="align-middle">Delete</span>
                            </Button>
                        </td>
                    )
                }
            }
        ]
    };

    return (
        <CardContent.Container>
            <CardContent.Header className="d-flex align-items-center">
                <h4 className="page-header">E-Tax Auto Config</h4>
                <Button className="ms-auto">
                    <FaPlus className="me-1" />
                    <span className="align-middle">Create E-Tax</span>
                </Button>
            </CardContent.Header>
            <CardContent.Body>
                <Datatable prepareData={prepareData} />
            </CardContent.Body>
        </CardContent.Container>
    );
}

export default ETaxAutoConfig;