import '../styles/TopNav.css';
import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import { ReactComponent as ETaxLogo } from '../assets/images/etax-logo.svg';

function TopNav() {
    return (
        <header>
            <Navbar className="main-navbar shadow-sm">
                <Container fluid>
                    <Navbar.Brand>
                        <ETaxLogo />
                    </Navbar.Brand>
                </Container>
            </Navbar>
        </header>
    );
}

export default TopNav;